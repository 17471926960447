#formTallyCreation{
    display: flex;
    flex-direction: column;
}

#formTallyCreation div{
    margin: 5px;
}

#buttonLine button{
    margin: 5px;
}