#myProgress {
    width: 100%;
    background-color: grey;
    height: 10px;
}

#myBar {
    width: 1%;
    height: 10px;
    background-color: white;
}
