.infoEvent div {
    display: flex;
    flex-direction: row;
}

.infoEvent :nth-child(2n) {
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
    justify-content: flex-end;
}

.infoEvent :nth-child(2n) div{
    display: flex;
    align-items: flex-end;
}
