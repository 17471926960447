body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px;

  background-image: url("altyor_photo_accueil.png");
  background-color: black;
  background-attachment: fixed;
  background-size: cover;
}

html {
  height: 100%;

  color: white;
}

.blinking {
  animation: mymove 2s ease infinite;
}

@keyframes mymove {
  from {
    color: red;
  }

  to {
    color: orange;
  }
}

#root {
  height: 100%;
}

.extended {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.elementBorder{
  margin: 10px;
  padding: 10px;
  border: 6px ridge orange;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
}

